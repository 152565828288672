import React, { useState, useEffect } from 'react'
import logoWhite from "../images/enimeris-logo-website-white.png";
import fbWhite from "../images/enimeris-social-icons-white-fb.svg";
import linkedInWhite from "../images/enimeris-social-icons-white-linkedin.svg";

import "./FooterMenu.css";


function FooterMenu(props) {

    console.log(props)
    const menu = props.footerMenu ? props.footerMenu.menuItems.edges : [];

    useEffect(() => {
        if (document.querySelectorAll(".slash")[0]) {
            document.querySelectorAll(".slash")[0].style.display = "none";
        }
    }, [])

    return (
        <div className="footer-container mx-auto px-5">
            <nav className=" container m-auto flex flex-wrap lg:flex-no-wrap flex-col lg:flex-row lg:justify-around items-start pt-16 pb-8">
                <div className="flex flex-row flex-wrap justify-between w-full md:w-auto pb-8 lg:pb-0">
                    <img className="w-64 m-auto" src={logoWhite} alt="white-logo" />
                    <div className="w-full flex justify-center mt-8">
                        <a href="https://www.facebook.com/enimeris/" target="_blank"><img src={fbWhite} style={{ width: "45px" }} /></a>
                        <a href="https://www.linkedin.com/company/enimeris/" target="_blank" className="pl-4"><img src={linkedInWhite} style={{ width: "45px" }} /></a>
                    </div>
                </div>
                <div className="flex items-center">
                    <ul className="flex flex-wrap lg:flex-no-wrap flex-col lg:flex-row footer-links">
                        {menu.map((tab, index) => {
                            if (!tab.node.label.includes("#")) {
                                return (
                                    <li key={`hMenu-${index}`} className="md:pr-2 xl:pr-8 pt-8 md:pt-0">
                                        <div>
                                            {tab.node.label}
                                        </div>
                                        <ul className="text-sm mt-4">
                                            {tab.node.childItems.edges && tab.node.childItems.edges.map((subItem, yndex) => (
                                                <li key={`f-sub-item-${yndex}`} className="mt-3 w-full f-link-w">
                                                    {subItem.node.url.includes("f2.enimeris.com")
                                                        ?
                                                        <a href={subItem.node.url.replace("https://f2.enimeris.com", "https://enimeris.com")}>
                                                            {subItem.node.label}
                                                        </a>
                                                        :
                                                        <a href={subItem.node.url.includes("#") ? subItem.node.url.replace("#", "https://enimeris.com") : subItem.node.url.replace("http://", "https://enimeris.com/")}>
                                                            {subItem.node.label}
                                                        </a>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </div>
            </nav>
            <div className="w-full text-white pt-16 pb-8">
                <ul className="flex flex-row flex-wrap justify-center">
                    {menu.map((tab, index) => {
                        if (tab.node.label.includes("#")) {
                            return (
                                <li key={`subMenu-${index}`} class="sub-link-mob" >
                                    <span className="px-2 slash">|</span>
                                    <a href={tab.node.url.replace("f2.enimeris.com", "enimeris.com")}>
                                        {tab.node.label.replace("#", "")}
                                    </a>
                                </li>
                            )
                        }
                    })}
                    <li class="sub-link-mob" >
                        <span className="px-2 slash">|</span>
                        <a href="https://f2.enimeris.com/wp-content/uploads/2020/06/ΚΑΤΑΣΤΑΤΙΚΟ-ΕΝΗΜΕΡΙΣ.pdf" target="_blank">
                            Καταστατικό
                        </a>
                    </li>

                </ul>
                <div>
                    <h5 className="text-center">© 2022 Enimeris. All rights reserved.</h5>
                    <div className='flex justify-center items-end pt-2'>
                        <img src="https://w3vitals.com/static/logo-ed79ddefb962ec8aa0d647d19df0d638.png" style={{width: "50px"}}/>
                        <h5 className='pl-2'><a href='https://w3vitals.com' target='_blank'>Δημιουργία ιστοσελίδας από W3Vitals</a>.</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FooterMenu;