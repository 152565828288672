import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import _ from 'lodash'

import useDebounce from './../utilities/useDebounce'
import logoWhite from "../images/enimeris-logo-website-white.png";
import queryString from 'query-string'
import "./../components/synergates.css"
import htmlDecode from "html-entities-decoder";
import Down from "../images/chev-down.svg";
import Footer from "../components/FooterMenu"



function Associates(props) {

    const [filteredJobs, setFilteredJobs] = useState()
    const [onlyCityFilteredJobs, setOnlyCityFilteredJobs] = useState()
    const [locationList, setLocationList] = useState()
    const [indValue, setIndValue] = useState("allIndustries")
    const [indLabel, setIndLabel] = useState()
    const [indOptions, setIndOptions] = useState(false)

    const [cityValue, setCityValue] = useState("allCities")
    const [cityLabel, setCityLabel] = useState()
    const [cityOptions, setCityOptions] = useState(false)



    let data = props.data.wpgraphql
    console.log(data)
    useEffect(() => {

        let search = queryString.parse(props.location.search);
        if (search.city || search.page || search.industry) {
            filterJobs(data.jobs, search)
        }

        if (search.city) {
            console.log(locationList)
            setCityValue(search.city)
            data.jobLocations.edges.map((loc) => {
                console.log(loc)
                if (loc.node.slug === search.city) {
                    setCityLabel(loc.node.name)
                }
            })
        } else {
            setCityValue("all cities")
            setCityLabel("Επιλέξτε πόλη")
        }

        if (search.industry) {
            console.log(data.jobIndustries)

            data.jobIndustries.edges.map((job) => {
                if (job.node.slug === search.industry) {
                    setIndLabel(job.node.name)
                }
            })
            setIndValue(search.industry)
        } else {
            setIndValue("allIndustries")
            setIndLabel("Επιλέξτε κλάδο εργασίας")
        }

        let locationFilters;
        let locationTmp = createLocationFilters(data.jobs, search)

        setLocationList(locationTmp);

    }, [])

    function filterJobs(jobs, filters) {
        let fJobs = jobs.edges;
        if (filters.city) {
            let fCity = filters.city.split(",")
            fJobs = _.filter(fJobs, function (job) {
                let shouldReturn = false;
                job.node.jobLocations.edges.map((loc) => {
                    if (fCity.includes(loc.node.slug)) {
                        shouldReturn = true;
                    }
                })
                return shouldReturn ? job : null;
            })

            setOnlyCityFilteredJobs(fJobs)
        }

        if (filters.industry) {
            fJobs = _.filter(fJobs, function (job) {
                let shouldReturn = false;
                job.node.jobIndustries.edges.map((industry) => {
                    if (filters.industry === industry.node.slug) {
                        shouldReturn = true;
                    }
                })
                return shouldReturn ? job : null;
            })
        }
        setFilteredJobs(fJobs)
    }

    function createLocationFilters(jobs, filters) {
        console.log(filters)
        let locationTmp = {};
        let fJobs = jobs.edges ? jobs.edges : jobs;
        console.log(fJobs)
        fJobs.map((job) => {
            // console.log(job)
            job.node.jobLocations.edges.map((loc) => {
                if (filters.industry) {
                    job.node.jobIndustries.edges.map((industry) => {
                        if (filters.industry === industry.node.slug) {
                            if (locationTmp[loc.node.slug]) {
                                locationTmp[loc.node.slug].counter = locationTmp[loc.node.slug].counter + 1
                            } else {
                                locationTmp[loc.node.slug] = {
                                    name: loc.node.name,
                                    counter: 1
                                }
                            }
                        }
                    })
                } else {
                    if (locationTmp[loc.node.slug]) {
                        locationTmp[loc.node.slug].counter = locationTmp[loc.node.slug].counter + 1
                    } else {
                        locationTmp[loc.node.slug] = {
                            name: loc.node.name,
                            counter: 1
                        }
                    }
                }
                // console.log(loc.node.name)
            })
        })

        console.log(locationTmp)

        return locationTmp;
    }

    function changeLocationList(loc) {
        var query = queryString.parse(props.location.search);
        console.log(loc)
        if (loc !== "allCities") {
            query.city = loc;
            document.location.href = `https://career.enimeris.com/synergates?${queryString.stringify(query)}`;
        } else {
            delete query.city;
            document.location.href = `https://career.enimeris.com/synergates?${queryString.stringify(query)}`;
        }
    }

    function changeIndustry(ind) {
        console.log(ind)
        var query = queryString.parse(props.location.search);
        if (ind !== "allIndustries") {
            query.industry = ind;
            document.location.href = `https://career.enimeris.com/synergates?${queryString.stringify(query)}`;
        } else {
            delete query.industry;
            document.location.href = `https://career.enimeris.com/synergates?${queryString.stringify(query)}`;
        }
    }

    function findFilteredJobsLength(job, index) {
        console.log(cityValue)
        if (cityValue === "all cities") {
            return job.jobs.edges.length;
        } else if (cityValue !== "all cities") {
            console.log(job)
            console.log(onlyCityFilteredJobs)
            let tmp = 0

            onlyCityFilteredJobs.map((item, i) => {
                console.log(item.node.jobIndustries.edges[0].node.slug)
                console.log(job)
                if (item.node.jobIndustries.edges[0].node.slug === job.slug) {
                    tmp = tmp + 1
                }
            })
            return tmp;
        }
    }

    return (
        <div>

            <Helmet>
                <html lang={"el"} />
                <title>Θέσεις Εργασίας στο Δίκτυο Συνεργατών μας | Καριέρα Enimeris</title>
                <meta name="description" content="Διεκδικήστε εργασία στην Ελλάδα και ευκαιρίες για εργασία στο εξωτερικό μέσα από το δίκτυο συνεργατών μας. Βρείτε την κατάλληλη θέση εργασίας σήμερα." />
            </Helmet>

            <div>
                <div className="text-white synergates-intro flex justify-center">
                    <Link to={"/"}>
                        <img className="w-56 md:w-64 m-auto py-8" src={logoWhite} alt="white-logo" />
                    </Link>
                </div>
            </div>

            <div className="container m-auto py-16 flex flex-wrap">
                <div className="w-full option-panel">
                    <div className="pb-8">
                        <div className="text-2xl text-white">
                            <h1 className="w-full text-center text-3xl">Θέσεις Εργασίας στο Δίκτυο Συνεργατών Μας</h1>
                            {/* <div><h2>Αναζήτηση θέσεων εργασίας</h2></div> */}
                        </div>
                        <div className="text-xl text-white pt-4">
                            Διαθέσιμες θέσεις εργασίας:
                            {(!filteredJobs && data.jobs.edges) && (
                                <span className="pl-1">{data.jobs.edges.length}</span>
                            )}

                            {(filteredJobs) && (
                                <span className="pl-1">{filteredJobs.length}</span>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-wrap md:px-16">
                        <div className="w-full text-center md:text-left md:w-1/2 md:pr-4 relative">
                            <div onClick={() => setIndOptions(!indOptions)} className="custom-select p-2 cursor-pointer">
                                <div className="flex justify-between">
                                    {indValue
                                        ?
                                        <span>{indLabel}</span>
                                        :
                                        <span>Επιλέξτε κλάδο εργασίας</span>
                                    }
                                    <div><img src={Down} className={`chev-down transform ${indOptions ? "rotate-180" : "rotate-0"}`} /></div>
                                </div>
                            </div>
                            {indOptions && (
                                <div className="custom-options-container">
                                    <ul>
                                        <li onClick={() => changeIndustry("allIndustries")}>
                                            Όλες
                                        </li>
                                        {data.jobIndustries && data.jobIndustries.edges.map((industry, i) => {
                                            if( !filteredJobs && (industry.node.jobs.edges.length > 0)){
                                                return (
                                                    <li onClick={() => changeIndustry(industry.node.slug)} key={`industry${i}`} >{industry.node.name + " (" + `${industry.node.jobs.edges.length}` + ")"}</li>
                                                )
                                            }else if(filteredJobs && findFilteredJobsLength(industry.node, i) > 0 ){
                                                return (
                                                    <li onClick={() => changeIndustry(industry.node.slug)} key={`industry${i}`} >{industry.node.name + " (" + `${findFilteredJobsLength(industry.node, i)}` + ")"}</li>
                                                )
                                            }
                                            
                                        })}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className="w-full pt-4 md:pt-0 text-center md:text-left md:w-1/2 md:pr-4relative">
                            <div onClick={() => setCityOptions(!cityOptions)} className="custom-select p-2 cursor-pointer">
                                <div className="flex justify-between">
                                    {cityValue
                                        ?
                                        <span>{cityLabel}</span>
                                        :
                                        <span>Επιλέξτε περιοχή</span>
                                    }
                                    <div><img src={Down} className={`chev-down transform ${cityOptions ? "rotate-180" : "rotate-0"}`} /></div>
                                </div>
                            </div>
                            {cityOptions && (
                                <div className="custom-options-container">
                                    <ul>
                                        <li onClick={() => changeLocationList('allCities')}>
                                            Όλες
                                        </li>

                                        {locationList && Object.keys(locationList).map((location, i) => {
                                            return (
                                                <li
                                                    key={`location${i}`}
                                                    onClick={() => changeLocationList(location)}
                                                >
                                                    {locationList[location].name + " (" + locationList[location].counter + ")"}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
                <div className="w-full">
                    {!filteredJobs && data.jobs.edges.map((job, i) => (
                        <div key={`job-${i}`} className="text-2xl job-tab">
                            <div>
                                {htmlDecode(job.node.title)}
                            </div>
                            <div className="text-xl" dangerouslySetInnerHTML={{ __html: job.node.excerpt }} />
                            <Link className="learn-more" to={`/synergates/${job.node.slug}`}>Περισσότερα</Link>
                        </div>
                    ))}

                    {filteredJobs && filteredJobs.map((job, i) => (
                        <div key={`fjob-${i}`} className="text-2xl job-tab">
                            <div>
                                {htmlDecode(job.node.title)}
                            </div>
                            <div className="text-xl" dangerouslySetInnerHTML={{ __html: job.node.excerpt }} />
                            <Link className="learn-more" to={`/synergates/${job.node.slug}`}>Περισσότερα</Link>
                        </div>
                    ))}
                </div>
            </div>
            <Footer footerMenu={data.footer} />
        </div>
    )
}


export default Associates

export const pageQuery = graphql`
	query {
		wpgraphql {
			jobs(first: 200) {
                edges {
                    node {
                        uri
                        title
                        excerpt
                        slug
                        jobIndustries (first: 200) {
                            edges {
                                node {
                                    name
                                    slug
                                }
                            }
                        }
                        jobCompanies (first: 200) {
                            edges {
                                node {
                                    name
                                    id
                                }
                            }
                        }
                        jobLocations (first: 200){
                            edges {
                                node {
                                    name
                                    slug
                                }
                            }
                        }
                    }
                }
            }
            jobIndustries (first: 200){
                edges{
                    node{
                        name
                        slug
                        jobs{
                            edges{
                                node{
                                title
                                }
                            }
                        }
                    }
                }
            }
            jobLocations (first: 200){
                edges{
                    node{
                        name
                        slug
                    }
                }
            }
            footer: menu(id:"bmF2X21lbnU6MjQ="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
		}
	}
`
